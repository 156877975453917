module.exports = {
  chooseTheRightType: 'Wybierz właściwy typ działalności',
  businessType: 'Rodzaj działalności',
  invitationLinkEmpty: 'Link jest niedostępny',

  // divider
  companyName: 'Nazwa firmy',
  fullName: 'Imię i nazwisko',

  // divider
  contactPersonEmail: 'Adres email',
  invoiceEmail: 'Adres email do faktur',
  pesel: 'PESEL',
  nip: 'NIP',

  // divider
  bankAccount: 'Numer konta bankowego',

  // divider
  servicesDescription: 'Opis świadczonych usług',

  // divider
  contactPersonName: 'Reprezentant',
  contactAddress: 'Adres email reprezentanta',
  contactPersonPhone: 'Numer telefonu reprezentanta',
  technicalPersonName: 'Osoba techniczna',
  technicalPersonEmail: 'Adres email os. techn.',
  technicalPersonPhone: 'Nr. telefonu os. techn.',
  addressStreet: 'Ulica i nr. domu',
  companyAddress: 'Ulica i nr. domu',
  addressPostCode: 'Kod pocztowy',
  contactPostalCode: 'Kod pocztowy',
  companyPostalCode: 'Kod pocztowy',
  addressCity: 'Miejscowość',
  contactCity: 'Miejscowość',
  companyCity: 'Miejscowość',
  countryName: 'Kraj',
  country: 'Kraj',
  country2: 'Kraj',
  typePhoneNumber: 'Numer telefonu',
  phoneNumber: 'Numer telefonu',
  Poland: 'Polska',

  typeBasicData: 'Podaj podstawowe dana Twojej firmy',
  representativeData: 'Dane osoby upoważnionej do zawarcia umowy',
  techData:
    'Dane osoby do kontaktu w sprawach technicznej jeśli nie ma dedykowanej osoby',
  mainAddress: 'Podaj adres głównej siedziby firmy',
  correspondenceAddressData: 'Podaj adres do korespondencji',
  phoneNumberErrorMessage: 'Podaj poprawny numer telefonu',
  topWarning:
    'Jeśli chcesz uruchomić płatności dla pacjentów w rejestracji online musisz zarejestrować się w systemie Przelewów24. Wypełnij poniższy formularz, a my prześlemy Twoje dane do Przelewów24. O następnych krokach dowiesz się z kolejnych wiadomości z Przelewy24. Po zakończeniu procedury otrzymamy wiadomość od P24 i będziesz mogła/mógł akceptować płatności w Twoim gabinecie. Cała procedura zazwyczaj zajmuje ok. 2 dni roboczych. ',
  bottomInfo:
    'Po podaniu danych prześlemy je do Przelewów24. Twój przedstawiciel Przelewy24 skontaktuje się z Tobą, z informacją, że możesz zakończyć procedurę i podpisać dostęp. Po podpisaniu umowy Przelewy24 prześlą do nas informacje i będziemy mogli wdrożyć dla Ciebie płatności.',
  saveButton: 'Wyślij do Przelewy24',
  serviceAvailability: 'Usługa dostępna wyłącznie na terenie Polski',
  isDataCorrect: 'Czy dane się zgadzają?',
  townName: 'Miejscowość',
  correspondenceAddress: 'Ulica i numer domu',
  correspondenceZipCode: 'Kod pocztowy',
  correspondenceCountry: 'Kraj',
  correspondenceTownName: 'Miasto',
  NIP: 'NIP',
  REGON: 'REGON',
  KRS: 'KRS',
  regon: 'REGON',
  krs: 'KRS',
  representativePesel: 'PESEL',
  addressCountry: 'Kraj',
  modalSaveButton: 'Dane się zgadzają, wyślij do Przelewy24',
  cancelAndCorrect: 'Anuluj i popraw',
  formCongrats:
    'Dziękujemy za wypełnienie formularza. Twoje dane zostały wysłane do Przelewów24. Aby dokończyć rejestrację wejdź na poniższy link i dokończ rejestrację.',

  // left side
  headers: {
    basicData: 'Dane Twojej Firmy',
    representativeData: 'Reprezentant',
    technicalData: 'Kontakt do osoby technicznej',
    address: 'Adres',
    correspondenceAddressData: 'Adres korespondencyjny'
  },

  identificators: {
    PESELNumber: 'PESEL',
    NIPNumber: 'NIP',
    REGONNumber: 'REGON',
    KRSNumber: 'KRS'
  },

  activityTypeOptions: {
    naturalPerson: 'Osoba fizyczna',
    soleProprietoship: 'Jednoosobowa działalność gospodarcza',
    civilPartnership: 'Spółka cywilna',
    generalPartnership: 'Spółka jawna',
    limitedPartnership: 'Spółka komandytowa',
    limitedJointStockPartnership: 'Spółka komandytowo-akcyjna',
    jointStockCompany: 'Spółka akcyjna',
    limitedLiabilityCompany: 'Spółka z ograniczoną odpowiedzialnością',
    associationFoundationEtc:
      'Stowarzyszenie, fundacja, organizacja pożytku publicznego',
    cooperative: 'Spółdzielnia'
  }
}
