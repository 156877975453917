import { Middleware } from '@nuxt/types'

const navigationDuplicationMiddleware: Middleware = ({ app, route }) => {
  const { router } = app

  if (router?.currentRoute && router?.currentRoute.path === route.path) {
    throw new Error('Navigation Duplicated')
  }
}

export default navigationDuplicationMiddleware
