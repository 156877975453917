module.exports = {
  addGroup: 'Dodaj grupę',
  groupName: 'Nazwa grupy',
  startDate: 'Data rozpoczęcia',
  finishDate: 'Data zakończenia',
  assignedTo: 'Pacjent przypisany do',
  typeTherapistNameToSelect: 'Podaj nazwę terapeuty',
  searchPlaceholder: 'Wyszukaj pacjenta, grupę',
  menu: {
    finishTherapy: 'Zakończ terapię',
    history: 'Historia',
    delete: '@:common.delete',
    confirmDelete: 'Chcesz usunąć tę grupę?'
  },
  tabs: {
    meetingHistory: 'Historia spotkań',
    history: 'Historia',
    groupMembers: 'Członkowie grupy',
    attachments: 'Załączniki',
    invoices: 'Faktury & płatności'
  },
  table: {
    empty: 'Brakuje grup, proszę dodaj używając przycisku Dodaj grupę.',
    emptyOnSearch: 'Nie znaleziono grup odpowiadających Twoim kryteriom.'
  }
}
