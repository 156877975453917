module.exports = {
  title: 'Raporty ośrodka / gabinetu',
  reportWillBeSent: 'Raport zostanie wysłany na twojego maila',
  comparisonText:
    'Porównywane okresy: {previousStart} - {previousEnd} i {currentStart} - {currentEnd}',
  dashboard: {
    title: 'Podsumowanie',
    income: 'Przychód',
    dynamicsDescription:
      'Dochód w wybranym okresie w porównaniu do poprzedniego okresu',
    totalPlannedIncome: {
      title: 'Całkowity planowany dochód',
      description: 'Dochód ze wszystkich zaplanowanych sesji'
    },
    paidServices: {
      title: 'Opłacone usługi',
      description: 'Do tej pory opłacony przychód'
    },
    unpaidMeetings: {
      title: 'Nieopłacone spotkania',
      description: 'Przychód, który nie został jeszcze opłacony / zaksięgowany'
    },
    lostIncome: {
      title: 'Utracony przychód',
      description: 'Przychody utracone z powodu odwołanych spotkań'
    }
  },
  services: {
    title: 'Usługi',
    meetings: {
      meetingsHeld: {
        title: 'Liczba odbytych spotkań',
        description: 'Liczba odbytych spotkań w wybranym okresie'
      },
      meetingsScheduled: {
        title: 'Liczba zaplanowanych spotkań',
        description: 'Liczba zaplanowanych spotkań w wybranym okresie'
      },
      meetingsCancelled: {
        title: 'Odwołane spotkania',
        description: 'Liczba odwołanych spotkań w wybranym okresie.'
      },
      patientsNumber: {
        title: 'Liczba pacjentów',
        description: 'Liczba różnych pacjentów w wybranym okresie'
      },
      therapiesFinished: {
        title: 'Terapie zakończone',
        description: 'Liczba terapii zakończonych w wybranym okresie.'
      },
      therapiesStarted: {
        title: 'Rozpoczęte nowe terapie',
        description: 'Liczba nowych terapii rozpoczętych w tym okresie.'
      }
    },
    breakdown: {
      title: 'Rozbicie na usługi',
      currentPeriod: 'Wybrany okres',
      previousPeriod: 'Poprzedni okres',
      table: {
        empty: 'Nie ma usług w wybranym okresie'
      }
    }
  },
  employees: {
    title: 'Pracownicy',
    chooseEmployee: 'Wybierz pracownika',
    eventPresenceSelect: 'Wybierz statusy spotkań',
    eventPaymentSelect: 'Wybierz statusy płatności spotkań',
    eventPaymentMethodSelect: 'Wybierz metody płatności',
    download: 'Pobierz PDF',
    overview: {
      meetingsScheduled: {
        title: 'Liczba zaplanowanych spotkań',
        description: 'Liczba zaplanowanych spotkań w wybranym okresie'
      },
      meetingsHeld: {
        title: 'Liczba odbytych spotkań',
        description: 'Liczba odbytych spotkań w wybranym okresie'
      },
      meetingsCancelled: {
        title: 'Liczba odwołanych spotkań',
        description: 'Liczba odwołanych spotkań w wybranym okresie. '
      },
      heldMeetingsRate: {
        title: 'Wskaźnik odbytych spotkań',
        description:
          'Procent odbytych spotkań w stosunku do zaplanowanych spotkań'
      }
    }
  },
  servicesBreakdown: {
    name: 'Nazwa usługi',
    totalEvents: 'Liczba spotkań',
    meetingsDuration: 'Czas',
    meetingsIncome: 'Suma',
    meetings: {
      patient: 'Pacjent',
      date: 'Data',
      price: 'Kwota',
      status: 'Status',
      emptyDesc: 'Zmień filtry na dowolne rekordy'
    }
  },
  table: {
    empty: 'Brak rekordów dla wybranych filtrów'
  }
}
