import { computed, useStore } from '@nuxtjs/composition-api'
import { QuickMeetingModalState } from '../types/navBar'

export const useNavBarStore = () => {
  const store = useStore<QuickMeetingModalState>()

  const isQuickMeetingVisible = computed(
    () => store.getters['navBar/getQuickMeeting']
  )

  const changeQuickMeetingVisibility = (visibility: boolean) => {
    store.commit('navBar/changeQuickMeetingVisibility', visibility)
  }

  return { isQuickMeetingVisible, changeQuickMeetingVisibility }
}
