module.exports = {
  addMember: 'Dodaj członka grupy',
  membershipAddedOn: 'Dodano dnia',
  membershipEndingOn: 'Zakończono dnia',
  searchPlaceholder: 'Wyszukaj pacjenta',
  confirmDelete: 'Chcesz usunąć tego członka grupy?',
  table: {
    empty:
      'Brakuje członków grupy, proszę dodaj używając przycisku Dodaj członka grupy.',
    emptyOnSearch:
      'Nie znaleziono członków grupy odpowiadających Twoim kryteriom.'
  }
}
