module.exports = {
  paymentInPerson: 'Płatność w gabinecie',
  paymentOnline: 'Płatność online w momencie rezerwacji terminu',
  configUpdated: 'Ustawienia płatności zaktualizowane',

  description: {
    bottom:
      'Wybierz przynajmniej jedną metodę płatności dostępna dla pacjentów przy rezerwacji online.',
    description: 'Płatność przy rezerwacji',
    onlineDescription:
      'Dostępne metody płatności w rejestracji online będą zależały od konfiguracji w panelu Przelewów24.'
  },

  radioOptions: {
    cash: 'Gotówką',
    card: 'Płatność kartą',
    blik: 'BLIK na telefon',
    other: 'Inne',
    przelewy24: 'Przelewy24',
    bankTransfer: 'Przelew tradycyjny'
  }
}
