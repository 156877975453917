import { Middleware } from '@nuxt/types'
import { Role, hasRoles } from '~/composable/userData'

const invoicesMiddleware: Middleware = ({ redirect, from }) => {
  if (!hasRoles([Role.Admin, Role.Reception, Role.Finance])) {
    redirect(from.path || '/calendar')
  }
}

export default invoicesMiddleware
