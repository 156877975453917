import { BannerState } from '../types/banner'
import { Announcement } from '~/generated/graphqlDjango'

export const state = (): BannerState => ({
  isDialogVisible: false,
  currentIndex: 0,
  announcements: [],
  isLoading: true
})

export const mutations = {
  setIsDialogVisible(state: BannerState, isDialogVisible: boolean) {
    state.isDialogVisible = isDialogVisible
  },
  setCurrentIndex(state: BannerState, currentIndex: number) {
    state.currentIndex = currentIndex
  },
  setAnnouncements(state: BannerState, announcements: Announcement[]) {
    state.announcements = announcements
  },
  setIsLoading(state: BannerState, isLoading: boolean) {
    state.isLoading = isLoading
  }
}

export const getters = {
  getIsDialogVisible: (state: BannerState) => state.isDialogVisible,
  getCurrentIndex: (state: BannerState) => state.currentIndex,
  getAnnouncements: (state: BannerState) => state.announcements,
  getIsLoading: (state: BannerState) => state.isLoading
}
