import { isProd } from '~/utils/env'

// eslint-disable-next-line no-unused-expressions
!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) f._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  t = b.createElement(e)
  t.async = !0
  t.src = v
  s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)
})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

window.fbq.disablePushState = true // not recommended, but can be done <- in docs that way

// wrapper for fbq to ensure we don't send events in dev
window.fbEvent = function (...args) {
  if (
    isProd()
    // && !isStaging() // uncomment for production
  )
    window.fbq(...args)
}

window.fbEvent('init', process.env.NUXT_ENV_META_PIXEL_ID)
