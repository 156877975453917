import { useStore, computed } from '@nuxtjs/composition-api'
import { Announcement } from '~/generated/graphqlDjango'

export const useAnnouncementsStore = () => {
  const store = useStore()

  const announcements = computed<Announcement[]>(
    () => store.getters['banner/getAnnouncements']
  )
  const isDialogVisible = computed<boolean>(
    () => store.getters['banner/getIsDialogVisible']
  )
  const currentIndex = computed<number>(
    () => store.getters['banner/getCurrentIndex']
  )
  const isLoading = computed<boolean>(
    () => store.getters['banner/getIsLoading']
  )
  const currentAnnouncement = computed<Announcement | null>(() => {
    return announcements.value[currentIndex.value] ?? null
  })
  const announcementsLength = computed<number>(
    () => announcements.value.length ?? 0
  )

  const setAnnouncements = (announcements: Announcement[]) => {
    store.commit('banner/setAnnouncements', announcements)
  }
  const setIsDialogVisible = (isDialogVisible: boolean) => {
    store.commit('banner/setIsDialogVisible', isDialogVisible)
  }
  const setCurrentIndex = (currentIndex: number) => {
    store.commit('banner/setCurrentIndex', currentIndex)
  }
  const setIsLoading = (isLoading: boolean) => {
    store.commit('banner/setIsLoading', isLoading)
  }
  return {
    announcements,
    isDialogVisible,
    currentIndex,
    isLoading,
    currentAnnouncement,
    announcementsLength,
    setAnnouncements,
    setIsDialogVisible,
    setCurrentIndex,
    setIsLoading
  }
}
