module.exports = {
  generated: 'Wygenerowano',
  at: 'o',
  fromDG: 'z DobryGabinet.com',
  therapy: {
    patient: 'Pacjent',
    therapist: 'Terapeuta',
    typeOfNotes: 'Rodzaj notatek',
    printDate: 'Data wystawienia',
    practiceName: '@:sessions.pdfFields.practiceName'
  }
}
