module.exports = {
  title: 'Czas pracy',
  availability: {
    edit: 'Edytuj dostępność',
    exceptions: {
      title: 'Wyjątki  w harmonogramie pracy',
      description:
        'Dodaj daty gdy Twoja dostępność zmieni się w stosunku do regularnych terminów'
    },
    unavailable: 'Niedostępny',
    tooltip: {
      add: 'Dodaj godziny pracy',
      delete: 'Usuń godziny pracy',
      copy: 'Kopiuj godziny pracy do kolejnego dnia'
    },
    sureRejectChanges: 'Na pewno chcesz odrzucić zmiany?',
    workTimeSaved: 'Czas pracy został zaktualizowany'
  },
  holidays: {
    title: 'Twoje plany urlopowe',
    description: `Poniżej znajdziesz listę Twoich nadchodzących urlopów/dni wolnych. W tym
    czasie kalendarz zostanie zablokowany i nie będzie można umawiać do
    Ciebie spotkań. Umówione już spotkania zostaną odwołane. Jeśli masz
    włączone SMSy system poinformuje o tym pacjentów z ustawionym przez
    Ciebie wyprzedzeniem`,
    statusTooltip: 'Zatwierdzone przez {by} {on}',
    awaitingApproval: 'Oczekuje na zatwierdzenie',
    restLeave: 'Urlop wypoczynkowy',
    sickLeave: 'Zwolnienie lekarskie',
    dialog: {
      title: 'Dodaj urlop',
      selectDateOrRange: 'Wybierz datę lub zakres urlopu',
      note: 'Notatka do prośby',
      notePlaceholder: 'Tutaj możesz wpisać dodać komentarz do prośby',
      infoContent:
        'Uwaga - dodanie urlopu odwoła zaplanowane w tym czasie spotkania. Jeśli masz włączone SMSy pacjenci dostaną o tym powiadomienie zgodnie z ustawionym wyprzedzeniem. ',
      selectType: 'Wybierz typ urlopu'
    },
    type: {
      restLeave: 'Urlop wypoczynkowy',
      sickLeave: 'Zwolnienie lekarskie',
      other: 'Inny'
    }
  }
}
