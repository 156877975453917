module.exports = {
  addNewRoom: 'Dodaj nowe pomieszczenie',
  editRoom: 'Edytuj pomieszczenie',
  searchEmployees: 'Wpisz terapeutę',
  chooseService: 'Wybierz usługę',
  form: {
    roomName: 'Nazwa pomieszczenia',
    roomSlug: 'Skrócona nazwa',
    availableOnline: 'Pomieszczenie jest dostępne online',
    preferredBy: 'Preferowane przez',
    excludeService: 'Wyłączone usługi',
    excludeServiceDescription:
      'Wyłączenie usługi z pokoju zmniejszy ilość terminów dostępnych w wyszukiwarce i rejestracji online. Otrzymasz również powiadomienie o próbie umówienia spotkania na wykluczoną usługę',
    comments: '@:common.comments',
    confirmDeleteRoom: 'Cchesz usunąć ten pokój?'
  },
  branch: 'Oddział',
  selectPracticeBranch: 'Wybierz oddział',
  preferredBy: 'Preferowany przez',
  excluded: 'Wyłączone usługi',
  comments: 'Uwagi',
  online: 'Online',
  confirmDelete:
    'Czy na pewno chcesz usunąć ten pokój? | Czy na pewno chcesz usunąć te pokoje?',
  eventLossWarning:
    'Nadchodzące spotkania powiązane z tym pokojem zostaną usunięte, serie spotkań zostaną zakończone w dniu dzisiejszym. | Nadchodzące spotkania powiązane z tymi pokojami zostaną usunięte, serie spotkań zostaną zakończone w dniu dzisiejszym.',
  table: {
    empty: 'Brakuje pokoi, proszę dodaj używając przycisku Dodaj Pokój.',
    noPracticeBranch: 'Oddział nie został wybrany, proszę wybierz oddział.'
  },
  soonToBeAdded: 'Pracujemy nad tym, dostępne wkrótce!',
  tooltips: {
    thisRoomIsOnline: 'To pomieszczenie jest dostępne dla spotkań online',
    clickToRemoveRoom: 'Kliknij, aby usunąć pokój'
  }
}
