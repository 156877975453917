import { Commit } from 'vuex/types'
import dayjs from 'dayjs'
import { InvoiceData, InvoicesState } from '../types/invoices'
import {
  BillingInfoInput,
  ExternalInvoiceKind,
  ExternalInvoicePaymentType,
  ExternalInvoiceStatus,
  InvoiceLineItem
} from '~/generated/graphqlDjango'
import {
  InvoiceForm,
  InvoiceInfoForm,
  InvoicePaymentForm,
  TableInvoiceLineItem
} from '~/types/Invoice'
import { DeepPartial } from '~/types/general'
import { DropdownElement } from '~/types/DropdownElement'

export const state = (): InvoicesState => ({
  invoiceForm: {
    info: {
      issueDate: new Date(),
      saleDate: new Date(),
      name: '',
      // this needs to be somehow translated
      notes:
        'Sprzedawca zwolniony podmiotowo z podatku VAT od towarów i usług na podstawie Art.113 ust. 1 i 9 ustawy o VAT z dnia 11 marca 2004, Dz. U. z 2011 Nr 177 poz. 1054 z późniejszymi zmianami.',
      kind: ExternalInvoiceKind.Vat,
      status: ExternalInvoiceStatus.Issued
    },
    recipient: {
      name: '',
      street: '',
      postCode: '',
      city: '',
      email: '',
      taxNo: ''
    },
    seller: {
      companyName: '',
      street: '',
      postCode: '',
      city: '',
      taxNo: '',
      bank: '',
      bankAccount: ''
    },
    items: [],
    payment: {
      paymentMethod: ExternalInvoicePaymentType.Transfer,
      currency: 'PLN',
      dueDate: dayjs().add(7, 'day').toDate().toString()
    },
    customItems: []
  },
  selectedPatient: null,
  isSelectInvoiceRecipientButtonVisible: true,
  invoiceLineItems: []
})

export const mutations = {
  setInvoiceForm(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    invoiceForm: DeepPartial<InvoiceForm> | null
  ) {
    state.invoiceForm = invoiceForm
  },
  setRecipient(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    recipient: BillingInfoInput | undefined
  ) {
    if (state.invoiceForm) {
      state.invoiceForm.recipient = recipient
    } else
      state.invoiceForm = {
        recipient
      }
  },
  setInvoiceCustomItems(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    customItems: TableInvoiceLineItem[]
  ) {
    if (state.invoiceForm) {
      state.invoiceForm.customItems = customItems
    }
  },
  setInvoiceCustomItem(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    data: {
      newValue: TableInvoiceLineItem
      itemId: number
    }
  ) {
    if (state.invoiceForm?.customItems) {
      state.invoiceForm.customItems[data.itemId] = data.newValue
    }
  },
  setInvoiceItems(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    items: TableInvoiceLineItem[]
  ) {
    if (state.invoiceForm) {
      state.invoiceForm.items = items
    }
  },
  setLineItems(
    state: {
      invoiceLineItems: InvoiceLineItem[]
    },
    items: InvoiceLineItem[]
  ) {
    state.invoiceLineItems = items
  },
  setInvoiceInfo(
    state: {
      invoiceForm: DeepPartial<InvoiceForm> | null
    },
    info: InvoiceInfoForm
  ) {
    if (state.invoiceForm) {
      state.invoiceForm.info = info
    }
  },
  setInvoiceItem(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    data: {
      newValue: TableInvoiceLineItem
      itemId: number
    }
  ) {
    if (state.invoiceForm?.items) {
      state.invoiceForm.items[data.itemId] = data.newValue
    }
  },
  setInvoiceRecipientButtonState(
    state: { isSelectInvoiceRecipientButtonVisible: boolean },
    value: boolean
  ) {
    state.isSelectInvoiceRecipientButtonVisible = value
  },
  setSelectedPatient(
    state: {
      selectedPatient: DropdownElement | null
    },
    patient: DropdownElement | null
  ) {
    state.selectedPatient = patient
  },
  setInvoicePayment(
    state: { invoiceForm: DeepPartial<InvoiceForm> | null },
    payment: InvoicePaymentForm
  ) {
    if (state.invoiceForm) {
      state.invoiceForm.payment = payment
    }
  }
}

export const getters = {
  // whole invoice object
  getInvoiceForm: (state: { invoiceForm: DeepPartial<InvoiceForm> | null }) =>
    state.invoiceForm,

  // recipient
  getInvoiceRecipient: (state: {
    invoiceForm: DeepPartial<InvoiceForm> | null
  }) => state.invoiceForm?.recipient,

  // info
  getInvoiceInfo: (state: { invoiceForm: DeepPartial<InvoiceForm> | null }) =>
    state.invoiceForm?.info,

  // payment
  getInvoicePayment: (state: {
    invoiceForm: DeepPartial<InvoiceForm> | null
  }) => state.invoiceForm?.payment,

  // selectedPatient
  getSelectedPatient: (state: { selectedPatient: DropdownElement | null }) =>
    state.selectedPatient,

  // recipient button
  getSelectInvoiceRecipient: (state: {
    isSelectInvoiceRecipientButtonVisible: boolean
  }) => state.isSelectInvoiceRecipientButtonVisible,

  // invoice items
  getCustomItems: (state: { invoiceForm: DeepPartial<InvoiceForm> | null }) =>
    state.invoiceForm?.customItems,
  getItems: (state: { invoiceForm: DeepPartial<InvoiceForm> | null }) =>
    state.invoiceForm?.items,
  getInvoiceLineItems: (state: { invoiceLineItems: InvoiceLineItem[] }) =>
    state.invoiceLineItems
}

export const actions = {
  transformAndSetInvoiceForm(
    { commit }: { commit: Commit },
    invoiceData: InvoiceData
  ) {
    const invoice = invoiceData?.invoice
    const externalInvoice = invoice?.externalInvoice

    const transformedData: DeepPartial<InvoiceForm> = {
      info: {
        issueDate: externalInvoice?.issueDate,
        saleDate: externalInvoice?.sellDate,
        notes: invoice?.notes,
        // invoiceNumber: invoice?.invoiceNumber,
        status: externalInvoice?.status ?? undefined
      },
      payment: {
        currency: 'PLN',
        dueDate: externalInvoice?.paymentTo,
        paymentMethod: externalInvoice?.paymentType ?? undefined
      },
      recipient: {
        name: externalInvoice?.buyerName ?? undefined,
        street: externalInvoice?.buyerStreet ?? undefined,
        postCode: externalInvoice?.buyerPostCode ?? undefined,
        city: externalInvoice?.buyerCity ?? undefined,
        email: externalInvoice?.buyerEmail ?? undefined,
        taxNo: externalInvoice?.buyerTaxNo
      },
      seller: {
        companyName: externalInvoice?.sellerName ?? undefined,
        street: externalInvoice?.sellerStreet ?? undefined,
        postCode: externalInvoice?.sellerPostCode ?? undefined,
        city: externalInvoice?.sellerCity ?? undefined,
        taxNo: externalInvoice?.sellerTaxNo,
        bank: externalInvoice?.sellerBank,
        bankAccount: externalInvoice?.sellerBankAccount
      },
      items: invoice?.lineItems,
      customItems: []
    }

    commit('setInvoiceForm', transformedData)
  }
}
