import { isProd } from '~/utils/env'

// const HJID = 2951106
// const HJSW = 6

// const runHotjar = () => {
//   if (typeof window !== 'undefined') {
//     ;(function (h, o, t, j, a, r) {
//       h.hj =
//         h.hj ||
//         function () {
//           ;(h.hj.q = h.hj.q || []).push(arguments)
//         }
//       h._hjSettings = { hjid: HJID, hjsv: HJSW }
//       a = o.getElementsByTagName('head')[0]
//       r = o.createElement('script')
//       r.async = 1
//       r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
//       a.appendChild(r)
//     })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
//   }
// }

if (isProd()) {
  console.log('Starting Hotjar...')
  // runHotjar()
}
